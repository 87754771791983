<template>
  <el-main>
    <el-tabs v-model="activeTabs">
      <el-tab-pane v-for="(item, index) in tabsList" :key="index" :label="item.name" :name="item.value"></el-tab-pane>
    </el-tabs>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
      <div v-if="activeTabs === 'one'">
        <div class="partTitle mb10">基本信息</div>
        <el-form-item label="服务分类：" prop="classify_id">
          <el-cascader
            class="mr30"
            v-model="ruleForm.classify_id"
            :props="{ value: 'id', label: 'name', children: '_child', multiple: true }"
            placeholder="请选择或搜索"
            clearable
            filterable
            :options="serviceClassify"
          ></el-cascader>
          <el-button type="text" @click="$router.push('/service/beautyServiceList')">没有分类,去添加分类</el-button>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input type="number" class="mr30" v-model="ruleForm.sort" placeholder="请输入内容"></el-input>
          <span class="tips">数字越大，排名越靠前；如为空，排序默认为添加时间越近越靠前</span>
        </el-form-item>
        <el-form-item label="服务名称：" prop="service_name">
          <el-input v-model="ruleForm.service_name" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="虚拟出售数量：">
          <el-input class="mr30" v-model="ruleForm.virtual_sales" placeholder="请输入内容"></el-input>
          <span class="tips">前端显示=虚拟出售数量+实际销售数量</span>
        </el-form-item>
        <el-form-item label="服务图片：" class="item" prop="service_picture">
          <ReadyUploadSource
            @getSource="getSource"
            @changeOrder="list => (ruleForm.service_picture = list)"
            @removeThis="index => ruleForm.service_picture.splice(index, 1)"
            :isMany="true"
            :manyPath="ruleForm.service_picture"
            :isManyMax="10"
            :maxSize="1024 * 1024"
          ></ReadyUploadSource>
          <p class="tips">建议图片尺寸800*800px，大小不超过1M，最多可以上传10张图片，其中第一张为主图（有主图视频除外）</p>
        </el-form-item>
        <el-form-item label="服务主图：" class="item" prop="service_cover_picture">
          <ReadyUploadSource
            @getSource="val => (ruleForm.service_cover_picture = val.path)"
            :path="ruleForm.service_cover_picture"
            @removeThis="() => (ruleForm.service_cover_picture = '')"
          ></ReadyUploadSource>
          <p class="tips">建议图片尺寸800*800px，大小不超过1M，仅支持1张</p>
        </el-form-item>
        <el-form-item label="主图视频：" class="item">
          <ReadyUploadSource
            @getSource="data => (ruleForm.service_video = data.path)"
            @removeThis="() => (ruleForm.service_video = '')"
            :path="ruleForm.service_video"
            type="video"
            :maxSize="1024 * 1024 * 50"
          ></ReadyUploadSource>
          <p class="tips">建议视频尺寸750px*750px，视频大小，限制50M/个以内，格式支持mp4、3gp、m3u8</p>
        </el-form-item>
        <div class="partTitle mb10">价格库存</div>
        <el-form-item label="服务价格：" prop="service_price">
          <el-input class="mr30" type="number" v-model="ruleForm.service_price" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="服务原价：">
          <el-input class="mr30" type="number" v-model="ruleForm.service_prime_cost" placeholder="请输入内容"></el-input>
        </el-form-item>
        <div class="partTitle mb10">其他信息</div>
        <el-form-item label="是否上架：" prop="status">
          <el-radio-group v-model="ruleForm.is_putaway">
            <el-radio :label="0">下架</el-radio>
            <el-radio :label="1">上架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="服务购买路径：">
          <el-radio-group v-model="ruleForm.service_type">
            <el-radio :label="0">下载文档</el-radio>
            <el-radio :label="1">展示账号</el-radio>
          </el-radio-group>
          <div v-if="!ruleForm.service_type">
            <el-input class="upload" disabled v-model="ruleForm.service_document">
              <el-button slot="append">
                上传文档
                <input type="file" ref="inputer" @change="getFile" />
              </el-button>
            </el-input>
          </div>
        </el-form-item>
      </div>
      <div v-if="activeTabs === 'two'">
        <el-form-item label="是否使用规格：">
          <el-switch @change="startUseSpec" v-model="ruleForm.is_spec" :inactive-value="0" :active-value="1"></el-switch>
        </el-form-item>
        <div v-if="ruleForm.is_spec">
          <el-form-item label="添加规格：">
            <div class="addSpecAllInfo">
              <el-button @click="addSpecItem">+ 添加规格</el-button>
              <p class="tips">最多支持添加3种规格</p>
              <!-- 渲染规格列表-->
              <div class="showSpecItem" v-if="ruleForm.spec.length">
                <div v-for="(item, index) in ruleForm.spec" :key="index">
                  <div class="inputBox">
                    规格名称：
                    <el-input placeholder="请输入规格名称" @change="noHaveAddReduce = false" v-model="item.spec_name"></el-input>
                  </div>
                  <div class="specDetail">
                    <div class="inputBox" v-for="(item1, index1) in item.spec_value" :key="index1">
                      规格详情：
                      <el-input placeholder="请输入规格详情" @change="noHaveAddReduce = false" v-model="item1.spec_value"></el-input>
                      <i class="el-icon-error" v-if="item.spec_value.length > 1" @click="deleteSpecDetail(item, index1, 2)"></i>
                    </div>
                    <el-button type="text" @click="addSpecDetail(item)">+ 添加详情</el-button>
                  </div>
                  <i class="el-icon-error" v-if="ruleForm.spec.length > 1" @click="deleteSpecDetail(ruleForm.spec, index, 1)"></i>
                </div>
              </div>
              <!-- 渲染规格详情table -->
              <div class="showSpecTable" v-if="ruleForm.spec.length">
                {{ specCount }}
                <table cellspacing="1">
                  <thead>
                    <tr>
                      <th v-for="(item, index) in ruleForm.spec" :key="index">{{ item.spec_name }}</th>
                      <th>价格</th>
                      <th>原价</th>
                      <th>图片</th>
                      <th v-if="ruleForm.service_type == 0">文档</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in ruleForm.spec_sku" :key="index">
                      <td v-for="(sku, index_sku) in item.sku" :key="index_sku">{{ sku.spec_value }}</td>
                      <td>
                        <el-input style="width: 100px" :min="0" type="number" v-model="item.price"></el-input>
                      </td>
                      <td>
                        <el-input style="width: 100px" :min="0" type="number" v-model="item.prime_cost"></el-input>
                      </td>
                      <td style="padding: 10px">
                        <ReadyUploadSource
                          :showStyle="{
                            width: '80px',
                            height: '80px',
                          }"
                          type="image"
                          :path="item.picture"
                          @removeThis="() => (item.picture = '')"
                          @getSource="val => (item.picture = val.path)"
                        ></ReadyUploadSource>
                      </td>
                      <td v-if="ruleForm.service_type == 0">
                        <el-input style="width: 350px" class="upload" disabled v-model="item.document">
                          <el-button slot="append">
                            上传文档
                            <input type="file" :ref="'specDocument' + index" @change="getSpecDocumentFile(index)" />
                          </el-button>
                        </el-input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="batchInput">
                批量输入：
                <el-input :min="0" type="number" placeholder="价格(元)" v-model="allPrice"></el-input>
                <el-input :min="0" type="number" placeholder="原价(元)" v-model="prime_cost"></el-input>
                <el-button type="primary" @click="batchInput">确定</el-button>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>

      <div v-if="activeTabs === 'three'">
        <el-form-item label="" label-width="0px">
          <RichText :richTxt="ruleForm.service_details" @soninfo="val => (ruleForm.service_details = val)"></RichText>
        </el-form-item>
      </div>
      <div v-if="activeTabs === 'four'">
        <el-form-item label="购买赠送积分数：">
          <el-input v-model="ruleForm.send_integral"></el-input>
          积分
          <p class="tips">填写后，用户购买获得积分</p>
        </el-form-item>
      </div>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
import _ from 'lodash';
import RichText from '@/components/richText';
export default {
  data() {
    function checkSpic(rule, value, callback) {
      if (!value.length) {
        callback(new Error('请上传服务图片'));
      } else {
        callback();
      }
    }
    return {
      update_id: null,
      update_info: {},
      noHaveAddReduce: true,
      activeTabs: 'one',
      tabsList: [
        { name: '基本信息', value: 'one' },
        { name: '服务规格', value: 'two' },
        { name: '服务详情', value: 'three' },
        { name: '营销设置', value: 'four' },
      ],
      ruleForm: {
        classify_id: [],
        sort: '',
        service_name: '',
        virtual_sales: '',
        service_picture: [],
        service_video: '',
        service_price: '',
        service_prime_cost: '',
        service_cover_picture: '',
        is_putaway: 1,
        is_spec: 0,
        // 规格列表
        spec: [],
        // 规格详情列表
        spec_sku: [],
        service_details: '',
        service_type: 0,
        service_document: '',
        send_integral: '',
      },
      rules: {
        classify_id: { required: true, message: '请选择服务分类', trigger: 'change' },
        service_name: { required: true, message: '请填写服务名称', trigger: 'blur' },
        service_picture: { required: true, validator: checkSpic, trigger: 'change' },
        service_price: { required: true, message: '请填写服务价格', trigger: 'blur' },
        status: { required: true, message: '请选择是否上架', trigger: 'change' },
        service_details: { required: true, message: '请填写服务详情', trigger: 'blur' },
        service_cover_picture: { required: true, message: '请上传服务主图', trigger: 'blur' },
      },
      serviceClassify: [],
      // 批量输入单价
      allPrice: '',
      // 批量输入原价
      prime_cost: '',
    };
  },
  components: {
    Preservation,
    ReadyUploadSource,
    RichText,
  },
  created() {
    this.getServiceClassify();
    if (this.$route.query.id) {
      this.update_id = this.$route.query.id;
      this.getUpdateServices();
    }
  },
  watch: {
    ruleForm() {
      this.$store.state.utils.is_update = 1;
    },
    'ruleForm.spec': {
      handler(val, old) {
        if (val) this.generateServiceSpec();
      },
      deep: true,
    },
  },
  computed: {
    // 存储一个根据规格生成的新数组
    newServiveSpecArr() {
      let arr = [];
      let arrLen = 1;
      if (this.ruleForm.spec?.length) {
        this.ruleForm.spec.map(item => {
          arrLen = arrLen * item.spec_value.length;
        });
        for (let i = 0; i < arrLen; i++) {
          let obj = {
            sku: [],
            prime_cost: '',
            price: '',
            picture: '',
          };
          if (this.ruleForm.service_type == 0) obj.document = '';
          // 编辑 -> 复写数据
          if (this.update_id && this.noHaveAddReduce) {
            obj.id = this.update_info.spec_sku[i].id;
            obj.spec_itme_ids = this.update_info.spec_sku[i].spec_itme_ids;
            obj.prime_cost = this.update_info.spec_sku[i].prime_cost;
            obj.price = this.update_info.spec_sku[i].price;
            obj.picture = this.update_info.spec_sku[i].picture;
            if (this.ruleForm.service_type == 0) {
              obj.document = this.update_info.spec_sku[i].document;
            }
          }
          arr.push(obj);
        }
      }
      return arr;
    },
  },
  methods: {
    getFile() {
      let files = this.$refs.inputer.files[0];
      let formData = new FormData();
      formData.append('files', files);
      this.$axios.uploadFile(this.$api.user.addFiles, formData).then(res => {
        if (res.code == 0) {
          this.ruleForm.service_document = res.result.path;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getSpecDocumentFile(index) {
      let files = this.$refs['specDocument' + index][0].files[0];
      let formData = new FormData();
      formData.append('files', files);
      this.$axios.uploadFile(this.$api.user.addFiles, formData).then(res => {
        if (res.code == 0) {
          this.ruleForm.spec_sku[index].document = res.result.path;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取要修改的服务信息
    getUpdateServices() {
      this.$axios
        .post(this.$api.gateway.service.serviceInfo, {
          id: this.update_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.update_info = res.result;
            let obj = _.cloneDeep(res.result);
            obj.classify_id = JSON.parse(res.result.classify_ids);
            obj.service_picture = JSON.parse(res.result.service_picture);
            this.ruleForm = obj;
          }
        });
    },
    // 点击确认保存按钮
    preservation() {
      let checkRes = this.checkDataLegitimacy();
      if (checkRes === false) return;
      let url = !this.update_id ? this.$api.gateway.service.addService : this.$api.gateway.service.editService;
      let obj = _.cloneDeep(this.ruleForm);
      if (this.update_id) {
        obj.id = Number(this.update_id);
      }
      obj.classify_ids = this.ruleForm.classify_id;
      obj.classify_id = this.ruleForm.classify_id.join(',');
      if (!obj.is_spec) {
        delete obj.spec;
        delete obj.spec_sku;
      }
      // 整理无用数据
      for (const key in obj) {
        if (obj[key] === '') {
          delete obj[key];
        }
      }
      this.$axios.post(url, obj).then(res => {
        if (res.code === 0) {
          let str = this.update_id ? '编辑成功' : '添加成功';
          this.$message.success(str);
          this.$store.state.utils.is_update = 0;
          this.$router.push('/gateway/service/serviceList');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 调用接口时检测数据是否合法
    checkDataLegitimacy() {
      if (!this.ruleForm.classify_id.length) {
        this.$message.warning('请选择服务分类');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.ruleForm.service_name) {
        this.$message.warning('请填写服务名称');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.ruleForm.service_picture.length) {
        this.$message.warning('请上传服务图片');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.ruleForm.service_price) {
        this.$message.warning('请填写服务价格');
        this.activeTabs = 'one';
        return false;
      }
      if (this.ruleForm.is_spec) {
        let flag = true;
        // 检测 spec 数据
        for (let i = 0; i < this.ruleForm.spec.length; i++) {
          if (this.ruleForm.spec[i].spec_name == '') {
            this.$message.warning('请将规格名称填写完整！！');
            flag = false;
            break;
          }
          delete this.ruleForm.spec[i].id;
          for (let j = 0; j < this.ruleForm.spec[i].spec_value.length; j++) {
            if (this.ruleForm.spec[i].spec_value[j].spec_value == '') {
              this.$message.warning('请将规格属性填写完整！！');
              flag = false;
              break;
            }
            if (!this.noHaveAddReduce) {
              delete this.ruleForm.spec[i].spec_value[j].id;
            }
          }
        }
        if (!flag) {
          this.activeTabs = 'two';
          return flag;
        }
        // 检测 spec_sku 数据
        for (let j = 0; j < this.ruleForm.spec_sku.length; j++) {
          if (this.ruleForm.service_type == 0) {
            if (this.ruleForm.spec_sku[j].price == '' || this.ruleForm.spec_sku[j].prime_cost == '' || this.ruleForm.spec_sku[j].picture == '' || this.ruleForm.spec_sku[j].document == '') {
              this.$message.warning('请将价格信息填写完整！！');
              flag = false;
              break;
            }
          }
          if (this.ruleForm.service_type == 1) {
            if (this.ruleForm.spec_sku[j].price == '' || this.ruleForm.spec_sku[j].prime_cost == '' || this.ruleForm.spec_sku[j].picture == '') {
              this.$message.warning('请将价格信息填写完整！！');
              flag = false;
              break;
            }
          }
        }
        if (!flag) {
          this.activeTabs = 'two';
          return flag;
        }
      }
      if (!this.ruleForm.service_details) {
        this.$message.warning('请填写服务详情！！');
        this.activeTabs = 'three';
        return false;
      }
    },
    // 获取组件中选中的图片
    getSource(list) {
      list.map(item => {
        this.ruleForm.service_picture.push(item.path);
      });
    },
    // 获取服务分类
    getServiceClassify() {
      this.$axios.post(this.$api.gateway.service.ServiceClassify).then(res => {
        if (res.code === 0) {
          this.serviceClassify = res.result;
        }
      });
    },
    // 开始使用规格
    startUseSpec() {
      if (this.ruleForm.is_spec && !this.ruleForm.spec.length) {
        let obj = {
          spec_name: '',
          spec_value: [{ spec_value: '' }, { spec_value: '' }],
        };
        this.ruleForm.spec.push(obj);
      }
    },
    // 添加一种规格
    addSpecItem() {
      if (this.ruleForm.spec.length == 3) {
        this.$message.error('最多只能添加3种规格');
        return;
      }
      this.noHaveAddReduce = false;
      let obj = {
        spec_name: '',
        spec_value: [{ spec_value: '' }, { spec_value: '' }],
      };
      this.ruleForm.spec.push(obj);
    },
    // 添加一种规格详情
    addSpecDetail(data) {
      data.spec_value.push({ spec_value: '' });
      this.noHaveAddReduce = false;
    },
    deleteSpecDetail(data, index, status) {
      let str = status === 2 ? '将删除这一规格详情！！！, 是否继续?' : '将删除这一规格！！！, 是否继续?';
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (status === 1) data.splice(index, 1);
          if (status === 2) data.spec_value.splice(index, 1);
          this.noHaveAddReduce = false;
        })
        .catch(() => {});
    },
    // 将规格数据加工成能渲染成表格的数组
    generateServiceSpec() {
      this.ruleForm.spec_sku = _.cloneDeep(this.newServiveSpecArr);
      // 收集每个规格的规格信息长度
      let spceLenArr = [];
      // 收集每个规格的规格信息
      let specObj = {};
      this.ruleForm.spec.map((item, index) => {
        specObj[`${index}`] = item.spec_value;
        spceLenArr.push(item.spec_value.length);
      });

      // 第一种、第二种、第三种规格每个规格属性需要生成多少条表格数据
      let specAddNum = [];
      spceLenArr.map((item, index) => {
        specAddNum[index] = 1;
        if (index > 0) {
          specAddNum[0] *= item;
        }
        if (index > 1) {
          specAddNum[1] *= item;
        }
      });
      // 规格长度为3时 储存状态
      let secondN = 1;
      let secondI = 0;
      for (let i = 0; i < this.ruleForm.spec_sku.length; i++) {
        let first = Math.floor(i / specAddNum[0]);
        this.ruleForm.spec_sku[i].sku.push({ spec_value: specObj[`0`][first].spec_value || '' });
        if (this.ruleForm.spec.length === 3) {
          //追加第二种规格
          let bigS = specObj[`1`].length * specAddNum[1];
          if (secondN <= specAddNum[1]) {
            this.ruleForm.spec_sku[i].sku.push({ spec_value: specObj[`1`][secondI].spec_value || '' });
            secondN++;
          } else {
            secondI++;
            if (!(i % bigS) && i !== 0) {
              secondI = 0;
            }
            this.ruleForm.spec_sku[i].sku.push({ spec_value: specObj[`1`][secondI].spec_value || '' });
            secondN = 2;
          }
          // 追加第三种规格 -> 最后一种规格追加模式
          let bigL = specObj[`2`].length * specAddNum[2];
          let Lindex = i % bigL;
          this.ruleForm.spec_sku[i].sku.push({ spec_value: specObj[`2`][Lindex].spec_value || '' });
        } else if (this.ruleForm.spec.length === 2) {
          let bigL = specObj[`1`].length * specAddNum[1];
          let Lindex = i % bigL;
          this.ruleForm.spec_sku[i].sku.push({ spec_value: specObj[`1`][Lindex].spec_value || '' });
        }
      }
    },
    // 点击确认批量输入
    batchInput() {
      this.ruleForm.spec_sku.map(item => {
        if (this.allPrice !== '') {
          item.price = this.allPrice;
        }
        if (this.prime_cost !== '') {
          item.prime_cost = this.prime_cost;
        }
      });
      this.allPrice = '';
      this.prime_cost = '';
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 80px;
  .upload {
    .el-button {
      position: relative;
      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
      }
    }
  }
  .partTitle {
    font-size: 14px;
    color: #000;
    font-weight: bold;
    line-height: 35px;
    border-bottom: 1px solid #c4c4cc;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .mr30 {
    margin-right: 30px;
  }
  .el-select,
  .el-input,
  .el-cascader {
    width: 400px;
    flex-shrink: 0;
  }
  .tips {
    font-size: 14px;
    color: #999;
    white-space: nowrap;
  }
  .addSpecAllInfo {
    .el-input {
      width: 300px;
    }
    .showSpecItem {
      & > div {
        padding: 20px;
        border: 1px solid #ccc;
        position: relative;
        & > i {
          position: absolute;
          top: 20px;
          right: 20px;
          font-size: 24px;
        }
      }
      & > div:not(:first-child) {
        border-top: none;
      }
      .specDetail {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        & > div {
          margin-right: 20px;
          margin-bottom: 10px;
        }
        & > .el-button {
          margin-bottom: 10px;
        }
      }
    }
  }
  .inputBox {
    position: relative;
    display: flex;
    align-items: center;

    i {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
      transform: translate(50%, -50%);
    }
  }
}
table {
  border-collapse: collapse;
  margin: 20px 0;
  thead {
    th {
      padding: 10px;
      border: 1px solid #dcdfe6;
      text-align: center;
    }
  }
  tbody {
    td {
      text-align: center;
      padding: 0 10px;
      border: 1px solid #dcdfe6;
    }
  }
}
</style>
