import { render, staticRenderFns } from "./serviceAdd.vue?vue&type=template&id=25706af7&scoped=true&"
import script from "./serviceAdd.vue?vue&type=script&lang=js&"
export * from "./serviceAdd.vue?vue&type=script&lang=js&"
import style0 from "./serviceAdd.vue?vue&type=style&index=0&id=25706af7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25706af7",
  null
  
)

export default component.exports